import Card, {
  CardContent,
  CardHeader,
  CardMainActionButtonProps,
  CardPlaceholder
} from '@/components/card/Card.component';
import DataTable, {
  DataTableBadgeCell,
  DataTableMenuCell
} from '@/components/data-table/DataTable.component';
import DatePicker from '@/components/date-picker';
import { displayDistributionTypeMap } from '@/models/ops/distribution-declarations/DisplayDistributionTypeMap.model';
import { DistributionDeclarationDto } from '@/models/ops/distribution-declarations/DistributionDeclarationDTO.model';
import { dividendStatusColorMap } from '@/models/ops/distribution-declarations/DividendStatusColorMap.model';
import { SecurityDto } from '@/models/ops/security/SecurityDTO.model';
import { FeatureLevelPermissions } from '@/models/UserPermissions.model';
import DistributionDeclarationsService, {
  DistributionDeclarationsSearchParams
} from '@/services/ops/distribution-declarations/DistributionDeclarations.service';
import DividendAccrualRatesService, {
  DividendAccrualRateSearchParams
} from '@/services/ops/dividend-accrual-rates/DividendAccrualRates.service';
import { userService } from '@/services/User.service';
import DOMInteraction from '@/utils/DOMInteraction';
import formatters from '@/utils/Formatters';
import { json2csvParser } from '@/utils/Json2csvParser';
import {
  Search as SearchIcon,
  StackedBarChartOutlined as StackedBarChartOutlinedIcon
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

import { AddDividendDeclarationDialog } from './AddDividendDeclarationDialog.component';
import { CalculateDividendDeclarationDialog } from './CalculateDividendDeclarationDialog.component';
import { DeleteDividendDeclarationDialog } from './DeleteDividendDeclarationDialog.component';
import { DividendDeclarationDetail } from './DividendDeclarationDetail.component';
import { EditDividendDeclarationDialog } from './EditDividendDeclarationDialog.component';
import { RecalculateDividendDeclarationDialog } from './RecalculateDividendDeclarationDialog.component';
import { ReverseDividendDeclarationDialog } from './ReverseDividendDeclarationDialog.component';
import { SecuritiesDetailUtils } from './utils/SecuritiesDetailUtils';

const exportDeclarations = async (
  params: DistributionDeclarationsSearchParams
) => {
  const data = await DistributionDeclarationsService.search({
    ...params,
    page: 1,
    pageSize: 0 // fetch all data
  });

  const csv = await json2csvParser(
    data.results.map(item => ({
      // format raw data to match visual presentation
      'Distribution Type': displayDistributionTypeMap[item.distributionType],
      'Payment Date': dayjs(item.paymentDate).format('MM/DD/YY'),
      'Record Date': item.recordDate
        ? dayjs(item.recordDate).format('MM/DD/YY')
        : undefined,
      'Reinvestment Date': item.reinvestDate
        ? dayjs(item.reinvestDate).format('MM/DD/YY')
        : undefined,
      'Reinvestment Price': item.reinvestPrice
    }))
  );

  let filename = 'dividend-declarations.csv';

  const paymentStartDate = dayjs(params.paymentStartDate).format('MM-DD-YYYY');
  const paymentEndDate = dayjs(params.paymentEndDate).format('MM-DD-YYYY');

  if (params.paymentStartDate && params.paymentEndDate) {
    filename = `dividend-declarations-${paymentStartDate}-to-${paymentEndDate}.csv`;
  } else if (params.paymentStartDate) {
    filename = `dividend-declarations-${paymentStartDate}.csv`;
  } else if (params.paymentEndDate) {
    filename = `dividend-declarations-${paymentEndDate}.csv`;
  }

  DOMInteraction.triggerDownload(csv, filename);
};

const exportAccrualDetails = async (
  params: DividendAccrualRateSearchParams
) => {
  const data = await DividendAccrualRatesService.search({
    ...params,
    page: 1,
    pageSize: 0 // fetch all data
  });

  const csv = await json2csvParser(
    data.results.map(item => ({
      'Accrual Rate': item.rate,
      // format raw data to match visual presentation
      'Effective Date': dayjs(item.effectiveDate).format('MM/DD/YY'),
      'Effective Day Count': item.dailyDayCount
    }))
  );

  let filename = 'dividend-accrual-details.csv';

  const effectiveStartDate = dayjs(params.effectiveStartDate).format(
    'MM-DD-YYYY'
  );

  const effectiveEndDate = dayjs(params.effectiveEndDate).format('MM-DD-YYYY');

  if (params.effectiveStartDate && params.effectiveEndDate) {
    filename = `dividend-accrual-details-${effectiveStartDate}-to-${effectiveEndDate}.csv`;
  } else if (params.effectiveStartDate) {
    filename = `dividend-accrual-details-${effectiveStartDate}.csv`;
  } else if (params.effectiveEndDate) {
    filename = `dividend-accrual-details-${effectiveEndDate}.csv`;
  }

  DOMInteraction.triggerDownload(csv, filename);
};

/**
 * Important to note that even though the db table, models, and service are named DistributionDeclaration,
 * the desired terminology for the UI is Dividend Declarations.
 */
type DividendDeclarationsCardProps = {
  security: SecurityDto;
  declarationsEndDate: string;
  declarationsStartDate: string;
  setDeclarationsEndDate: React.Dispatch<React.SetStateAction<string>>;
  setDeclarationsStartDate: React.Dispatch<React.SetStateAction<string>>;
  setAccrualEndDate: React.Dispatch<React.SetStateAction<string>>;
  setAccrualStartDate: React.Dispatch<React.SetStateAction<string>>;
  setDividendDeclarationForDetailView: React.Dispatch<
    React.SetStateAction<DistributionDeclarationDto | undefined>
  >;
};
const DividendDeclarationsCard = ({
  security,
  declarationsEndDate,
  declarationsStartDate,
  setDeclarationsEndDate,
  setDeclarationsStartDate,
  setAccrualStartDate,
  setAccrualEndDate,
  setDividendDeclarationForDetailView
}: DividendDeclarationsCardProps) => {
  const hasWritePermissions = userService.hasPermission(
    FeatureLevelPermissions.WRITE_SUBA_DIVIDENDS
  );
  const hasReadAccountsPermissions = userService.hasPermission(
    FeatureLevelPermissions.READ_SUBA_ACCOUNTS
  );

  const [
    openAddDividendDeclarationDialog,
    setOpenAddDividendDeclarationDialog
  ] = useState(false);
  const [
    openEditDividendDeclarationDialog,
    setOpenEditDividendDeclarationDialog
  ] = useState(false);
  const [
    openDeleteDividendDeclarationDialog,
    setOpenDeleteDividendDeclarationDialog
  ] = useState(false);
  const [openReverseDialog, setOpenReverseDialog] = useState(false);
  const [openCalculateDialog, setOpenCalculateDialog] = useState(false);
  const [openRecalculateDialog, setOpenRecalculateDialog] = useState(false);
  const [selectedDeclaration, setSelectedDeclaration] =
    useState<DistributionDeclarationDto>();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const paymentEndDate = declarationsEndDate || dayjs().format('YYYY-MM-DD');
  const paymentStartDate =
    declarationsStartDate || dayjs().subtract(30, 'days').format('YYYY-MM-DD');

  const searchParams = {
    cusip: security.cusip,
    page,
    pageSize,
    paymentEndDate,
    paymentStartDate
  };

  const { data, isFetching } = useQuery(
    ['DistributionDeclarationsService.search', ...Object.values(searchParams)],
    () => DistributionDeclarationsService.search(searchParams),
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity
    }
  );

  const handleAccrualRateFilter = (paymentDate: string) => {
    setAccrualEndDate(paymentDate);
    setAccrualStartDate(
      dayjs(paymentDate).subtract(30, 'days').format('YYYY-MM-DD')
    );
  };

  const handleSubmit = (values: {
    paymentEndDate: string;
    paymentStartDate: string;
  }) => {
    setDeclarationsEndDate(values.paymentEndDate);
    setDeclarationsStartDate(values.paymentStartDate);
  };

  const cardHeaderActionButtons: CardMainActionButtonProps[] = [
    {
      'data-testid': 'declarations-export-csv-button',
      disabled:
        isFetching ||
        !data?.results ||
        (Array.isArray(data.results) && !data.results.length),
      label: 'Export CSV',
      onClick: () => exportDeclarations(searchParams)
    }
  ];
  if (hasWritePermissions) {
    cardHeaderActionButtons.push({
      'data-testid': 'declarations-add-button',
      label: (
        <Stack alignItems='center' direction='row' spacing={0.5}>
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Add</Typography>
          <Stack sx={{ fontSize: 22 }}>
            <AddIcon fontSize='inherit' />
          </Stack>
        </Stack>
      ),
      onClick: () => setOpenAddDividendDeclarationDialog(true)
    });
  }

  // a step function is called through the API, so we don't know if it was
  // successful after requesting, so we invalidate cache after 10 seconds to give enough time
  // we will do a heavy invalidate of all queries due to multiple levels of queries populating the div pages
  const queryClient = useQueryClient();
  const invalidateSearchQuery = () => {
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, 10000);
  };

  return (
    <>
      {openAddDividendDeclarationDialog && (
        <AddDividendDeclarationDialog
          onClose={() => setOpenAddDividendDeclarationDialog(false)}
          security={security}
        />
      )}
      {openEditDividendDeclarationDialog && (
        <EditDividendDeclarationDialog
          declaration={selectedDeclaration}
          onClose={() => setOpenEditDividendDeclarationDialog(false)}
          security={security}
        />
      )}
      {openDeleteDividendDeclarationDialog && (
        <DeleteDividendDeclarationDialog
          declaration={selectedDeclaration}
          onClose={() => setOpenDeleteDividendDeclarationDialog(false)}
          security={security}
        />
      )}
      {selectedDeclaration && (
        <CalculateDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onCalculate={() => invalidateSearchQuery()}
          onClose={() => setOpenCalculateDialog(false)}
          open={openCalculateDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      {selectedDeclaration && (
        <ReverseDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onClose={() => setOpenReverseDialog(false)}
          onReverse={() => invalidateSearchQuery()}
          open={openReverseDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      {selectedDeclaration && (
        <RecalculateDividendDeclarationDialog
          cusip={selectedDeclaration.cusip}
          declarationId={selectedDeclaration.id}
          distributionType={selectedDeclaration.distributionType}
          dividendType={selectedDeclaration.dividendType}
          onClose={() => setOpenRecalculateDialog(false)}
          onRecalculate={() => invalidateSearchQuery()}
          open={openRecalculateDialog}
          reinvestDate={selectedDeclaration.reinvestDate}
        />
      )}
      <Card data-testid='declarations-card'>
        <CardHeader
          actionButtonsProps={cardHeaderActionButtons}
          title='Dividend Declarations'
        />
        <CardContent>
          <Formik
            initialValues={{
              paymentEndDate,
              paymentStartDate
            }}
            onSubmit={handleSubmit}>
            <Form data-testid='declarations-search-form'>
              <Stack
                direction={{ md: 'row', xs: 'column' }}
                spacing={2}
                sx={{
                  display: 'inline-flex' // avoid 100% width apply button on vertical variant
                }}>
                <FormControl variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='declarations-payment-start-date-input'
                    disableFuture
                    label='Payment Date Start'
                    name='paymentStartDate'
                    size='small' // FormControl doesn't pass to our DatePicker
                    sx={{ width: 200 }}
                    variant='outlined'
                  />
                </FormControl>
                <FormControl variant='outlined'>
                  <Field
                    as={DatePicker}
                    autoComplete='off'
                    data-testid='declarations-payment-end-date-input'
                    disableFuture
                    label='Payment Date End'
                    name='paymentEndDate'
                    size='small' // FormControl doesn't pass to our DatePicker
                    sx={{ width: 200 }}
                    variant='outlined'
                  />
                </FormControl>
                <Button
                  data-testid='declarations-apply-button'
                  sx={{ height: 40 }}
                  type='submit'
                  variant='outlined'>
                  Apply
                </Button>
              </Stack>
            </Form>
          </Formik>
        </CardContent>
        <CardContent disablePadding overlayLoading={isFetching}>
          <DataTable
            columnDefs={[
              {
                cellRenderer: (cellData: {
                  data: DistributionDeclarationDto;
                }) => {
                  const actions =
                    SecuritiesDetailUtils.determineStatusAndActionsByActivity(
                      cellData.data
                    );
                  const isAfterReinvest =
                    cellData.data.reinvestDate &&
                    dayjs().format('YYYY-MM-DD') > cellData.data.reinvestDate;

                  const canDelete =
                    cellData.data.dividendActivities === undefined ||
                    cellData.data.dividendActivities.length === 0;
                  const canReverse =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canReverse;
                  const canCalculate =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canCalculate;
                  const canRecalculate =
                    hasWritePermissions &&
                    isAfterReinvest &&
                    actions.canRecalculate;
                  const canViewParentAccounts =
                    hasReadAccountsPermissions &&
                    isAfterReinvest &&
                    actions.canViewParentAccounts;
                  return (
                    <DataTableMenuCell>
                      <MenuItem
                        onClick={() => {
                          setSelectedDeclaration(cellData.data);
                          setOpenEditDividendDeclarationDialog(true);
                        }}>
                        Edit
                      </MenuItem>
                      {canDelete && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenDeleteDividendDeclarationDialog(true);
                          }}>
                          Delete
                        </MenuItem>
                      )}
                      {canReverse && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenReverseDialog(true);
                          }}>
                          Reverse Dividend Declaration
                        </MenuItem>
                      )}
                      {canCalculate && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenCalculateDialog(true);
                          }}>
                          Calculate Dividend Declaration
                        </MenuItem>
                      )}
                      {canRecalculate && (
                        <MenuItem
                          onClick={() => {
                            setSelectedDeclaration(cellData.data);
                            setOpenRecalculateDialog(true);
                          }}>
                          Recalculate Dividend Declaration
                        </MenuItem>
                      )}
                      {canViewParentAccounts && (
                        <MenuItem
                          onClick={() => {
                            setDividendDeclarationForDetailView(cellData.data);
                          }}>
                          View Associated Parent Accounts
                        </MenuItem>
                      )}
                    </DataTableMenuCell>
                  );
                },
                cellStyle: {
                  paddingLeft: 16,
                  paddingRight: 16
                },
                field: 'cusip',
                headerName: '',
                maxWidth: 75,
                minWidth: 75
              },
              {
                autoHeight: true,
                cellRenderer: (cellData: {
                  data: DistributionDeclarationDto;
                }) => {
                  // fetch friendly display name for status
                  const activity =
                    SecuritiesDetailUtils.determineStatusAndActionsByActivity(
                      cellData.data
                    );
                  return (
                    <DataTableBadgeCell
                      color={dividendStatusColorMap[activity.overallStatus]}>
                      {formatters.displayCase(activity.overallStatus)}
                    </DataTableBadgeCell>
                  );
                },
                field: 'dividendMaster.status',
                headerName: 'Status',
                headerTooltip: 'Dividend Master Status',
                suppressMenu: true
              },
              {
                field: 'recordDate',
                headerTooltip: 'Record Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  value === null
                    ? ''
                    : formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'paymentDate',
                headerTooltip: 'Payment Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'reinvestDate',
                headerTooltip: 'Reinvest Date',
                suppressMenu: true,
                valueFormatter: ({ value }) =>
                  value === null
                    ? ''
                    : formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
              },
              {
                field: 'reinvestPrice',
                headerTooltip: 'Reinvest Price',
                suppressMenu: true,
                type: 'numericColumn',
                valueFormatter: ({ value }) =>
                  value === null ? '' : formatters.formatDollars(value, 2)
              },
              {
                cellRenderer: ({
                  data: rowData
                }: {
                  data: DistributionDeclarationDto;
                }) => {
                  if (rowData.dividendType === 'Accrual') {
                    return (
                      <Box color={theme => theme.palette.grey['500']}>
                        <IconButton
                          aria-label='See Accrual Details'
                          color='inherit'
                          data-testid={`declarations-accrual-details-button-${rowData.paymentDate}`}
                          onClick={() =>
                            handleAccrualRateFilter(rowData.paymentDate)
                          }>
                          <StackedBarChartOutlinedIcon />
                        </IconButton>
                      </Box>
                    );
                  }

                  return rowData.dividendRate || null;
                },
                field: 'dividendRate',
                headerName: 'Rate',
                suppressMenu: true,
                type: 'numericColumn'
              },
              {
                field: 'distributionType',
                headerName: 'Type',
                suppressMenu: true,
                valueFormatter: ({
                  value
                }: {
                  value: keyof typeof displayDistributionTypeMap;
                }) => displayDistributionTypeMap[value]
              }
            ]}
            columnSizing='auto'
            emptyPlaceholderComponent={
              <Stack
                alignItems='center'
                data-testid='no-declarations-message'
                justifyContent='center'
                sx={{ height: '100%' }}>
                <CardPlaceholder
                  icon={<SearchIcon fontSize='inherit' />}
                  subtitle='No declarations found'
                />
              </Stack>
            }
            onPageChanged={newPage => setPage(newPage)}
            onPageSizeChanged={newPageSize => setPageSize(newPageSize)}
            page={page}
            pageSize={pageSize}
            pagination
            paginationSource='server'
            paginationTotal={data?.pagination?.total}
            rowData={data?.results}
          />
        </CardContent>
      </Card>
    </>
  );
};

type AccrualDetailsCardProps = {
  accrualEndDate?: string;
  accrualStartDate?: string;
  cusip: string;
  setAccrualEndDate: React.Dispatch<React.SetStateAction<string>>;
  setAccrualStartDate: React.Dispatch<React.SetStateAction<string>>;
};
const AccrualDetailsCard = ({
  accrualEndDate,
  accrualStartDate,
  cusip,
  setAccrualEndDate,
  setAccrualStartDate
}: AccrualDetailsCardProps) => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [accrualEndDate, accrualStartDate, page, setPage]);

  const effectiveEndDate = accrualEndDate || dayjs().format('YYYY-MM-DD');
  const effectiveStartDate =
    accrualStartDate || dayjs().subtract(30, 'days').format('YYYY-MM-DD');

  const searchParams = {
    cusip,
    effectiveEndDate,
    effectiveStartDate,
    page,
    pageSize
  };

  const { data, isFetching } = useQuery(
    ['dividendAccrualRates', ...Object.values(searchParams)],
    () => DividendAccrualRatesService.search(searchParams),
    {
      keepPreviousData: true,
      retry: false,
      staleTime: Infinity
    }
  );

  const handleSubmit = (values: {
    effectiveEndDate: string;
    effectiveStartDate: string;
  }) => {
    setAccrualEndDate(values.effectiveEndDate);
    setAccrualStartDate(values.effectiveStartDate);
  };

  return (
    <Card data-testid='accrual-details-card'>
      <CardHeader
        actionButtonsProps={[
          {
            'data-testid': 'accrual-details-export-csv-button',
            disabled:
              isFetching ||
              !data?.results ||
              (Array.isArray(data.results) && !data.results.length),
            label: 'Export CSV',
            onClick: () => exportAccrualDetails(searchParams)
          }
        ]}
        title='Accrual Details'
      />
      <CardContent>
        <Formik
          enableReinitialize
          initialValues={{
            effectiveEndDate,
            effectiveStartDate
          }}
          onSubmit={handleSubmit}>
          <Form data-testid='accrual-details-search-form'>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              spacing={2}
              sx={{
                display: 'inline-flex' // avoid 100% width apply button on vertical variant
              }}>
              <FormControl variant='outlined'>
                <Field
                  as={DatePicker}
                  autoComplete='off'
                  data-testid='accrual-details-effective-start-date-input'
                  disableFuture
                  label='Effective Date Start'
                  name='effectiveStartDate'
                  size='small' // FormControl doesn't pass to our DatePicker
                  sx={(theme: Theme) => ({
                    width: 200,
                    [theme.breakpoints.between('lg', 'xl')]: { width: 155 }
                  })}
                  variant='outlined'
                />
              </FormControl>
              <FormControl variant='outlined'>
                <Field
                  as={DatePicker}
                  autoComplete='off'
                  data-testid='accrual-details-effective-end-date-input'
                  disableFuture
                  label='Effective Date End'
                  name='effectiveEndDate'
                  size='small' // FormControl doesn't pass to our DatePicker
                  sx={(theme: Theme) => ({
                    width: 200,
                    [theme.breakpoints.between('lg', 'xl')]: { width: 155 }
                  })}
                  variant='outlined'
                />
              </FormControl>
              <Button
                data-testid='accrual-details-apply-button'
                sx={{ height: 40 }}
                type='submit'
                variant='outlined'>
                Apply
              </Button>
            </Stack>
          </Form>
        </Formik>
      </CardContent>
      <CardContent disablePadding overlayLoading={isFetching}>
        <DataTable
          columnDefs={[
            {
              field: 'effectiveDate',
              suppressMenu: true,
              valueFormatter: ({ value }) =>
                formatters.formatFromIsoDateCustom(value, 'MM/DD/YY')
            },
            {
              field: 'rate',
              headerName: 'Accrual Rate',
              suppressMenu: true,
              type: 'numericColumn'
            },
            {
              field: 'dailyDayCount',
              headerName: 'Effective Day Count',
              suppressMenu: true,
              type: 'numericColumn'
            }
          ]}
          columnSizing='fit'
          emptyPlaceholderComponent={
            <Stack
              alignItems='center'
              data-testid='no-accrual-details-message'
              justifyContent='center'
              sx={{ height: '100%' }}>
              <CardPlaceholder
                icon={<SearchIcon fontSize='inherit' />}
                subtitle='No accrual details found'
              />
            </Stack>
          }
          onPageChanged={newPage => setPage(newPage)}
          onPageSizeChanged={newPageSize => setPageSize(newPageSize)}
          page={page}
          pageSize={pageSize}
          pagination
          paginationSource='server'
          paginationTotal={data?.pagination?.total}
          rowData={data?.results}
        />
      </CardContent>
    </Card>
  );
};

export const SecuritiesDetailDividendsTab = ({
  security
}: {
  security: SecurityDto;
}): JSX.Element => {
  const [declarationsEndDate, setDeclarationsEndDate] = useState('');
  const [declarationsStartDate, setDeclarationsStartDate] = useState('');

  const [accrualEndDate, setAccrualEndDate] = useState('');
  const [accrualStartDate, setAccrualStartDate] = useState('');

  const [
    dividendDeclarationForDetailView,
    setDividendDeclarationForDetailView
  ] = useState<DistributionDeclarationDto | undefined>();

  return (
    <>
      {!dividendDeclarationForDetailView ? (
        <Stack direction={{ lg: 'row', xs: 'column' }} spacing={2}>
          <Box flex={8}>
            <DividendDeclarationsCard
              declarationsEndDate={declarationsEndDate}
              declarationsStartDate={declarationsStartDate}
              security={security}
              setAccrualEndDate={setAccrualEndDate}
              setAccrualStartDate={setAccrualStartDate}
              setDeclarationsEndDate={setDeclarationsEndDate}
              setDeclarationsStartDate={setDeclarationsStartDate}
              setDividendDeclarationForDetailView={
                setDividendDeclarationForDetailView
              }
            />
          </Box>
          <Box flex={4}>
            <AccrualDetailsCard
              accrualEndDate={accrualEndDate}
              accrualStartDate={accrualStartDate}
              cusip={security.cusip}
              setAccrualEndDate={setAccrualEndDate}
              setAccrualStartDate={setAccrualStartDate}
            />
          </Box>
        </Stack>
      ) : (
        <DividendDeclarationDetail
          dividendDeclaration={dividendDeclarationForDetailView}
          onClose={() => setDividendDeclarationForDetailView(undefined)}
        />
      )}
    </>
  );
};
