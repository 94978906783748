import { DistributionDeclarationDto } from '@/models/ops/distribution-declarations/DistributionDeclarationDTO.model';
import { DividendAnnouncementAddRequest } from '@/models/ops/distribution-declarations/DividendAnnouncementAddRequest.model';
import { DividendAnnouncementRemoveRequest } from '@/models/ops/distribution-declarations/DividendAnnouncementRemoveRequest.model';
import { DividendAnnouncementUpdateRequest } from '@/models/ops/distribution-declarations/DividendAnnouncementUpdateRequest.model';
import { HistoricalDividendProcessInput } from '@/models/ops/distribution-declarations/HistoricalDividendProcessInput.model';
import ApiService from '@/services/Api.service';
import { DistributionType } from '@vestwell-sub-accounting/models/common/DistributionType';

type ApiResponse = {
  results: DistributionDeclarationDto[];
  pagination: {
    pageSize: number;
    page: number;
    total: number;
  };
};

export type DistributionDeclarationsSearchParams = {
  cusip: string;
  distributionType?: DistributionType;
  reinvestDate?: string;
  paymentStartDate?: string;
  paymentEndDate?: string;
  orderBy?: keyof DistributionDeclarationDto;
  orderByDirection?: string;
  page?: number;
  pageSize?: number;
};

class DistributionDeclarationsService {
  static async search(
    params: DistributionDeclarationsSearchParams
  ): Promise<ApiResponse> {
    for (const [key, value] of Object.entries(params)) {
      // filter empty params to avoid request validation errors
      if (value === undefined || value === '')
        delete params[key as keyof DistributionDeclarationsSearchParams];
    }

    return ApiService.getJson<ApiResponse>(`/dividend-declarations`, {
      cusip: params.cusip,
      distributionType: params.distributionType,
      orderBy: params.orderBy,
      orderByDirection: params.orderByDirection,
      page: params.page,
      pageSize: params.pageSize,
      paymentEndDate: params.paymentEndDate,
      paymentStartDate: params.paymentStartDate,
      reinvestDate: params.reinvestDate
    });
  }

  static async add(params: DividendAnnouncementAddRequest): Promise<void> {
    return ApiService.postJson('/dividend-declarations/add', params);
  }

  static async update(
    params: DividendAnnouncementUpdateRequest
  ): Promise<void> {
    return ApiService.postJson('/dividend-declarations/update', params);
  }

  static async remove(
    params: DividendAnnouncementRemoveRequest
  ): Promise<void> {
    return ApiService.postJson('/dividend-declarations/remove', params);
  }

  static async startHistorialDividendProcessor(
    payload: HistoricalDividendProcessInput
  ): Promise<void> {
    return ApiService.postJson(
      '/dividend-declarations/start-historical-dividend-processor',
      payload
    );
  }
}

export default DistributionDeclarationsService;
