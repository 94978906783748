import formatters from '@/utils/Formatters';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Typography
} from '@mui/material';

import { FC } from 'react';
import { useToggle } from 'react-use';

type ContributionBreakdownProps = {
  data: {
    sources: {
      rkIds: number[];
      type: string;
      value: number;
    }[];
    type: string;
    value: number;
  };
};

export const ContributionBreakdown: FC<ContributionBreakdownProps> = props => {
  const [open, toggle] = useToggle(false);

  return props.data?.sources?.length ? (
    <List sx={{ padding: 0, width: '100%' }}>
      <ListItemButton
        data-testid='exandable-row'
        onClick={toggle}
        sx={{ justifyContent: 'space-between', paddingX: 0 }}>
        <Typography data-testid='text'>{props.data?.type}</Typography>
        <Box display='flex'>
          <Typography mr={1} data-testid='value'>
            {formatters.formatDollars(props.data?.value)}
          </Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List>
          {props.data?.sources?.map(source => (
            <ListItem
              data-testid={`list-item-box-${source.type}`}
              key={source.type}
              sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography data-testid='text'>{source.type}</Typography>
              <Typography data-testid='value'>{formatters.formatDollars(source.value)}</Typography>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  ) : (
    <Box display='flex' flex={1} justifyContent='space-between'>
      <Typography data-testid='text'>{props.data?.type}</Typography>
      <Typography data-testid='value'>{formatters.formatDollars(props.data?.value)}</Typography>
    </Box>
  );
};
