import { useSnackbar } from '@/contexts/SnackBarContext';
import { CreateBillingPeriodDto } from '@/models/ops/fees/BillingDTO.model';
import UploadButton from '@/routes/plans/plan-detail/PlanActionTableV2/ConversionMainComponents/UploadButton.component';
import { PlanService } from '@/services/Plan.service';
import { json2csvParser } from '@/utils/Json2csvParser';
import { Error } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import { FC, ReactNode, useRef, useState } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface NewBillingPeriodDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const PlanFeeColumnDefs = [
  {
    alternates: [
      'PLAN_ID',
      'Plan ID',
      'SPONSOR_PLAN_ID',
      'Sponsor Plan ID',
      'SPONSOR PLAN',
      'SPONSOR_PLAN',
      'sponsorPlanId',
      'planId'
    ],
    field: 'planId',
    headerName: 'Plan ID',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'ADVISOR_FEE',
      'ADVISOR_FEES',
      'Advisor Fees',
      'advisor fee',
      'advisorFees',
      'advisorFee'
    ],
    field: 'advisorFee',
    headerName: 'Advisor Fee',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'ASSET_FEE',
      'ASSET_FEES',
      'Asset Fees',
      'asset fee',
      'assetFees',
      'assetFee'
    ],
    field: 'assetFee',
    headerName: 'Asset Fee',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'Per Participant Fee',
      'PER_PARTICIPANT_FEE',
      'PER_PARTICIPANT_FEES',
      'Per Participant Fees',
      'PARTICIPANT_FEE',
      'PARTICIPANT_FEES',
      'Participant Fees',
      'participant fee',
      'participantFees',
      'participantFee',
      'Ppt Fee'
    ],
    field: 'participantFee',
    headerName: 'Participant Fee',
    minWidth: 250,
    required: true,
    type: 'number'
  }
];

const SponsorFeeColumnDefs = [
  {
    alternates: [
      'PLAN_ID',
      'Plan ID',
      'SPONSOR_PLAN_ID',
      'Sponsor Plan ID',
      'SPONSOR PLAN',
      'SPONSOR_PLAN',
      'sponsorPlanId',
      'planId'
    ],
    field: 'planId',
    headerName: 'Plan ID',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'ADMINISTRATIVE_FEE',
      'ADMINISTRATIVE_FEES',
      'Administrative Fees',
      'Administrative Fee',
      'administrativeFee',
      'administrativeFees',
      'Admin Fee',
      'adminFee',
      'Admin Fees',
      'adminFees',
      'admin_fee',
      'admin_fees'
    ],
    field: 'administrativeFee',
    headerName: 'Administrative Fee',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'USE_FORFEITURE',
      'Use Forfeiture',
      'useForfeiture',
      'forfeiture'
    ],
    field: 'useForfeiture',
    headerName: 'Use Forfeiture',
    minWidth: 250,
    required: true,
    type: 'text'
  },
  {
    alternates: [
      'USE_SPENDING_BUDGET',
      'Use Spending Budget',
      'useSpendingBudget',
      'spendingBudget',
      'spending budget',
      'spending_budget'
    ],
    field: 'useSpendingBudget',
    headerName: 'Use Spending Budget',
    minWidth: 250,
    required: true,
    type: 'text'
  }
];

const ManagedAccountFeeDefs = [
  {
    alternates: ['participantId'],
    field: 'participantId',
    headerName: 'Participant ID',
    minWidth: 250,
    required: true,
    type: 'number'
  },
  {
    alternates: [
      'MANAGED_ACCOUNT_FEE',
      'MANAGED_ACCOUNT_FEES',
      'Managed Account Fees',
      'managedAccountFee',
      'managedAccountFees'
    ],
    field: 'managedAccountFee',
    headerName: 'Managed Account Fee',
    minWidth: 250,
    required: true,
    type: 'number'
  }
];

const NewBillingPeriodDialog: FC<NewBillingPeriodDialogProps> = props => {
  const formRef = useRef<any>();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const [updateError, setUpdateError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);

  const validateDocument = useMutation(
    ['PlanService.validateBillingFile'],
    (data: CreateBillingPeriodDto) => {
      return PlanService.validateBillingFile(file, data);
    },
    {
      onError: () => {
        snackbar.showSnackbar({
          message: 'Failed to create a billing period!',
          severity: 'error'
        });
      },
      onSuccess: async result => {
        if (result?.errors?.length) {
          setUpdateError(JSON.stringify(result.errors, null, 2));
          snackbar.showSnackbar({
            message: 'Failed to validate the billing period file!',
            severity: 'error'
          });
          return;
        }
        if (result?.id) {
          navigate(`/ops/fee-management/${result.id}`);
          snackbar.showSnackbar({
            message: 'Successfully created a new billing period!',
            severity: 'success'
          });
        }

        queryClient.invalidateQueries();
        props.onClose();
      }
    }
  );

  const validateStartDate = (value: Date) => {
    if (!value) return 'Date is required!';
    else if (value.toString() === 'Invalid Date') return 'Wrong date value!';

    return null;
  };

  const validateEndDate = (value: Date, startDate: Date) => {
    if (!value) return 'Date is required!';
    else if (value.toString() === 'Invalid Date') return 'Wrong date value!';
    else if (dayjs(value).isBefore(startDate))
      return 'Date must be after Period Start Date';

    return null;
  };

  const handleUpload = async (rows, uploadedFile) => {
    const csvData = await json2csvParser(rows);
    const blob = new Blob([csvData], { type: uploadedFile?.type });
    const file = new File([blob], uploadedFile?.name, {
      type: uploadedFile?.type
    });
    setFile(file);
  };

  return (
    <Dialog fullWidth maxWidth='sm' open={props.isOpen}>
      <Formik
        initialValues={{
          notes: '',
          periodEndDate: '',
          periodStartDate: '',
          purpose: 'PlanFees',
          recordkeeperName: 'Vestwell Sub-Accounting Platform'
        }}
        innerRef={formRef}
        onSubmit={async data => {
          try {
            await validateDocument.mutateAsync({
              ...data,
              periodEndDate: dayjs(data.periodEndDate).format('YYYY-MM-DD'),
              periodStartDate: dayjs(data.periodStartDate).format('YYYY-MM-DD'),
              purpose: data.purpose
            } as CreateBillingPeriodDto);
            setFile(null);
          } catch (e) {
            setUpdateError(
              e?.data?.errors
                ? JSON.stringify(e?.data?.errors, null, 2)
                : e?.message
            );
          }
        }}
        validateOnBlur={false}
        validateOnChange={false}>
        {formik => (
          <>
            <DialogContent
              sx={{
                p: 2,
                pb: 0.5
              }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Form data-testid='new-billing-period-form'>
                  <Typography mb={2} variant='h6'>
                    New Billing Period
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          minHeight: 150
                        }}
                        variant='outlined'>
                        <UploadButton
                          columnDefs={
                            formik.values.purpose === 'PlanFees'
                              ? PlanFeeColumnDefs
                              : formik.values.purpose === 'SponsorFees'
                                ? SponsorFeeColumnDefs
                                : ManagedAccountFeeDefs
                          }
                          onUpload={handleUpload}
                        />
                        <Typography variant='body2'>
                          {file?.name || 'No file chosen'}
                        </Typography>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Field
                          as={DatePicker}
                          data-testid='period-start-date-input'
                          label='Period Start Date'
                          name='periodStartDate'
                          onChange={(newValue: Date | null) => {
                            formik.setFieldValue('periodStartDate', newValue);
                          }}
                          size='small'
                          slotProps={{
                            textField: {
                              error: !!formik.errors.periodStartDate,
                              helperText: formik.errors
                                .periodStartDate as ReactNode,
                              id: 'periodStartDate',
                              size: 'small',
                              variant: 'outlined'
                            }
                          }}
                          validate={validateStartDate}
                          value={dayjs(formik.values.periodStartDate)}
                          variant='outlined'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Field
                          as={DatePicker}
                          data-testid='period-end-date-input'
                          label='Period End Date'
                          minDate={formik.values.periodStartDate}
                          name='periodEndDate'
                          onChange={newValue => {
                            formik.setFieldValue('periodEndDate', newValue);
                          }}
                          size='small'
                          slotProps={{
                            textField: {
                              error: !!formik.errors.periodEndDate,
                              helperText: formik.errors
                                .periodEndDate as ReactNode,
                              id: 'periodEndDate',
                              size: 'small',
                              variant: 'outlined'
                            }
                          }}
                          validate={date =>
                            validateEndDate(
                              date,
                              dayjs(formik.values.periodStartDate).toDate()
                            )
                          }
                          value={dayjs(formik.values.periodEndDate)}
                          variant='outlined'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={
                          formik.touched.notes && Boolean(formik.errors.notes)
                        }
                        fullWidth
                        helperText={
                          (formik.touched.notes &&
                            formik.errors.notes?.toString()) ||
                          'Optional. Add relevant tickets if any.'
                        }
                        id='notes'
                        label='Note'
                        name='notes'
                        onChange={formik.handleChange}
                        size='small'
                        value={formik.values.notes}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        name='purpose'
                        row
                        value={formik.values.purpose}>
                        <FormControlLabel
                          control={<Radio />}
                          label='Plan Fee'
                          onChange={formik.handleChange}
                          value='PlanFees'
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label='Sponsor Fee'
                          onChange={formik.handleChange}
                          value='SponsorFees'
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label='Managed Account Fee'
                          onChange={formik.handleChange}
                          value='ManagedAccountFees'
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                </Form>
              </LocalizationProvider>
            </DialogContent>
            <DialogActions sx={{ padding: '1.5rem' }}>
              <Button
                color='primary'
                onClick={() => props.onClose()}
                variant='text'>
                CANCEL
              </Button>
              <LoadingButton
                loading={validateDocument.isLoading}
                onClick={() => formik.submitForm()}
                variant='text'>
                PREVIEW
              </LoadingButton>
              {updateError && (
                <Tooltip
                  disableFocusListener
                  sx={{ maxWidth: 500 }}
                  title={
                    <React.Fragment>
                      <Typography
                        component='pre'
                        sx={{
                          fontSize: 12,
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word'
                        }}>
                        {updateError}
                      </Typography>
                    </React.Fragment>
                  }>
                  <Error color='error' sx={{ mx: 1 }} />
                </Tooltip>
              )}
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default NewBillingPeriodDialog;
