import TextStack, {
  TextLabel,
  TextStackItem,
  TextValue
} from '@/components/text-stack';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { DistributionDeclarationDto } from '@/models/ops/distribution-declarations/DistributionDeclarationDTO.model';
import { DividendAnnouncementRemoveRequest } from '@/models/ops/distribution-declarations/DividendAnnouncementRemoveRequest.model';
import { SecurityDto } from '@/models/ops/security/SecurityDTO.model';
import DistributionDeclarationsService from '@/services/ops/distribution-declarations/DistributionDeclarations.service';
import formatters from '@/utils/Formatters';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import dayjs from 'dayjs';

type DeleteDividendDeclarationDialogProps = {
  onClose: () => void;
  security: SecurityDto;
  declaration: DistributionDeclarationDto;
  onDelete?: () => void;
};

export const DeleteDividendDeclarationDialog = ({
  onClose,
  security,
  onDelete,
  declaration
}: DeleteDividendDeclarationDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const DeleteDividendDeclarationDialogMutation = useMutation(
    ['DistributionDeclarationsService.Delete'],
    (params: DividendAnnouncementRemoveRequest) =>
      DistributionDeclarationsService.remove(params),
    {
      onError: () => {
        showSnackbar({
          message: 'Error deleting Dividend Declaration',
          severity: 'error'
        });
      },
      onSuccess: () => {
        showSnackbar({
          message: 'Dividend Declaration Deleted',
          severity: 'success'
        });

        queryClient.invalidateQueries([
          'DistributionDeclarationsService.search'
        ]);

        onClose();
        if (typeof onDelete === 'function') {
          onDelete();
        }
      }
    }
  );

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      onClose={() => {
        onClose();
      }}
      open>
      <DialogTitle pb={1}>Delete Dividend Declaration</DialogTitle>
      <DialogContent
        sx={{
          p: 0
        }}>
        <Box
          bgcolor='primary.light'
          data-testid='dividend-declaration-remove-summary'
          px={3.25}
          py={2}>
          <Typography>{security.description || '\u2014'}</Typography>
          <Typography variant='body2'>
            {formatters.formatSecurityName(security?.symbol, security?.cusip) ||
              '\u2014'}
          </Typography>
        </Box>
        <Box p={3}>
          <TextStack direction='row' divider rowColumnWidth='dynamic'>
            <TextStackItem>
              <TextLabel>Type</TextLabel>
              <TextValue data-testid='dividend-declaration-dividend-type'>
                {declaration.dividendType}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Payment Date</TextLabel>
              <TextValue data-testid='dividend-declaration-payment-date'>
                {dayjs(declaration.paymentDate).format('MM/DD/YYYY')}
              </TextValue>
            </TextStackItem>
            <TextStackItem>
              <TextLabel>Reinvest Date</TextLabel>
              <TextValue data-testid='dividend-declaration-reinvest-date'>
                {dayjs(declaration.reinvestDate).format('MM/DD/YYYY')}
              </TextValue>
            </TextStackItem>
          </TextStack>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions
        sx={{
          px: 3,
          py: 2.25
        }}>
        <Button
          data-testid='dividend-declaration-cancel-button'
          disabled={DeleteDividendDeclarationDialogMutation.isLoading}
          onClick={() => {
            onClose();
          }}>
          Cancel
        </Button>
        <LoadingButton
          color='warning'
          data-testid='dividend-declaration-delete-button'
          loading={DeleteDividendDeclarationDialogMutation.isLoading}
          onClick={() =>
            DeleteDividendDeclarationDialogMutation.mutate({
              id: declaration.id
            })
          }
          variant='contained'>
          <Typography color='white'>Delete</Typography>
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
