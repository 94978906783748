import DatePicker from '@/components/date-picker';
import { useSnackbar } from '@/contexts/SnackBarContext';
import { PostContributionPayload } from '@/models/Contribution.model';
import ContributionService from '@/services/Contribution.service';
import { PlanService } from '@/services/Plan.service';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Unstable_Grid2 as Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';

import dayjs from 'dayjs';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import * as yup from 'yup';

import { findAvailableDates } from '../helpers';

type OffCycleProps = {
  planId: number;
  sponsorId: number;
  isOpen: boolean;
  onClose: () => void;
};

const NO_DIVISION_NAME = 'NO_DIVISION_NAME';

const validations = yup.object().shape({
  comment: yup.string().when(['lostGainsNeeded', 'responsibleParty'], {
    is: (lostGainsNeeded, responsibleParty) =>
      lostGainsNeeded && responsibleParty === 'Needs further review',
    then: () => yup.string().required('Required')
  }),
  date: yup.date().required('Required'),
  division: yup
    .string()
    .when('isSinglePayGroup', {
      is: false,
      then: () => yup.string().required('Required')
    })
    .nullable(),
  isExistingDate: yup
    .boolean()
    .test('isExistingDate', 'The date already exists', (value, ctx) => {
      if (!ctx.parent?.date) return true;

      return !ctx.parent?.existingDates?.includes(
        dayjs(ctx.parent?.date).format('MM/DD/YYYY')
      );
    }),
  lostGainsNeeded: yup.boolean(),
  responsibleParty: yup.string().when('lostGainsNeeded', {
    is: true,
    then: () => yup.string().required('Required')
  })
});

export const OffCycle: FC<OffCycleProps> = props => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const contributions = useQuery(
    ['PlanService.getContributions', props.planId],
    () => PlanService.getContributions(props.planId),
    { refetchOnMount: false }
  );

  const payrollSetups = useQuery(
    ['PlanService.getPayrollSetups', props.planId, props.isOpen],
    async () => await PlanService.getPayrollSetups(props.planId),
    {
      enabled: props.isOpen,
      select: payrollSetups =>
        payrollSetups?.filter(payrollSetup => payrollSetup.isActive)
    }
  );

  const postOffCycleContribution = useMutation(
    (data: PostContributionPayload) =>
      ContributionService.postContribution(data),
    {
      onError: () =>
        snackbar.showSnackbar({
          message: 'Failed to start off-cycle contribution!',
          severity: 'error'
        }),
      onSuccess: response => {
        navigate(
          `/plans/${props.planId}/contributions/${response?.ucid}/submission`
        );
      }
    }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      availableDates: [],
      comment: '',
      date: dayjs().format('MM/DD/YYYY'),
      division: '',
      existingDates: [],
      isExistingDate: undefined,
      isSinglePayGroup: true,
      lostGainsNeeded: false,
      responsibleParty: ''
    },
    onSubmit: async values =>
      postOffCycleContribution.mutateAsync({
        flowSubtype: 'off-cycle_supplemental_pay',
        isOffCycle: true,
        payrollDate: dayjs(values.date).format('MM/DD/YYYY'),
        sponsorId: props.sponsorId,
        sponsorPlanId: props.planId,
        ...(values.division && values.division !== NO_DIVISION_NAME
          ? { division: values.division }
          : {}),
        ...(values.lostGainsNeeded
          ? { lostGainsNeeded: values.lostGainsNeeded }
          : {}),
        ...(values.responsibleParty
          ? { responsibleParty: values.responsibleParty }
          : {}),
        ...(values.lostGainsNeeded &&
        values.responsibleParty === 'Needs further review'
          ? { comment: values.comment }
          : {})
      }),
    validateOnMount: true,
    validationSchema: validations
  });

  const onDateSelect = useCallback(
    (date: string) => formik.setFieldValue('date', date),
    []
  );

  const onCommentChanged = useCallback(
    event => formik.setFieldValue('comment', event.target?.value),
    []
  );

  useUpdateEffect(() => {
    if (!props.isOpen) {
      formik.resetForm();
    }
  }, [props.isOpen]);

  useUpdateEffect(() => {
    const isSinglePayGroup = payrollSetups.data?.length <= 1;

    formik.setFieldValue(
      'division',
      isSinglePayGroup ? payrollSetups.data?.[0]?.division : ''
    );
    formik.setFieldValue('isSinglePayGroup', isSinglePayGroup);
  }, [payrollSetups.data]);

  useUpdateEffect(() => {
    const existingDates = contributions.data
      ?.filter(
        contribution =>
          contribution.ucid &&
          contribution.isOffCycle &&
          contribution.division ===
            (formik.values?.division === NO_DIVISION_NAME
              ? undefined
              : formik.values?.division)
      )
      ?.map(contribution => contribution.payrollDate);

    formik.setFieldValue('existingDates', existingDates);
  }, [contributions.data, formik.values?.division]);

  useUpdateEffect(() => {
    if (formik.errors?.isExistingDate) {
      const futureDates = findAvailableDates(
        formik.values?.date,
        formik.values?.existingDates
      )?.reverse();

      const pastDates = findAvailableDates(
        formik.values?.date,
        formik.values?.existingDates,
        { step: -1 }
      );

      formik.setFieldValue('availableDates', [...futureDates, ...pastDates]);

      return;
    }

    formik.setFieldValue('availableDates', []);
  }, [formik.errors?.isExistingDate, formik.values?.date]);

  useUpdateEffect(() => {
    if (formik.values?.responsibleParty !== 'Needs further review') {
      formik.setFieldValue('comment', '');
    }
  }, [formik.values?.responsibleParty]);

  return (
    <Dialog
      data-testid='offCycle-dialog'
      fullWidth
      maxWidth='sm'
      onClose={props.onClose}
      open={props.isOpen}>
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle data-testid='offCycle-dialog-title'>
          New Off Cycle Contribution
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' display='flex' spacing={2}>
            <Grid xs={6}>
              <FormControl
                data-testid='offCycle-dialog-date'
                fullWidth
                required>
                <DatePicker
                  InputProps={{ autoComplete: 'off' }}
                  data-testid='offCycle-dialog-pay-date'
                  disabled={
                    contributions.isFetching || payrollSetups.isFetching
                  }
                  errorMessage={formik.errors?.date}
                  label='Pay date'
                  name='date'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  size='small'
                  value={formik.values.date}
                  variant='outlined'
                />
              </FormControl>
            </Grid>
            {formik.values?.isSinglePayGroup && (
              <Grid>
                <Typography data-testid='offCycle-dialog-paygroup-textbox'>
                  Pay Group Name:{' '}
                  {payrollSetups.data?.[0]?.payGroupName ?? 'null'}
                </Typography>
              </Grid>
            )}
            {!formik.values?.isSinglePayGroup && (
              <Grid xs={6}>
                <FormControl data-testid='offCycle-dialog-payGroup-dropdown' fullWidth>
                  <InputLabel>Pay group</InputLabel>
                  <Select
                    data-testid='division'
                    label='Pay group'
                    name='division'
                    onChange={formik.handleChange}
                    value={formik.values.division}>
                    {payrollSetups.data?.map(payrollSetup => (
                      <MenuItem
                        key={payrollSetup.division}
                        value={
                          payrollSetup.division
                            ? payrollSetup.division?.toString()
                            : NO_DIVISION_NAME
                        }>
                        {payrollSetup.payGroupName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.lostGainsNeeded}
                    defaultChecked
                    name='lostGainsNeeded'
                    onChange={formik.handleChange}
                  />
                }
                data-testid='offCycle-dialog-lostGainsNeeded'
                label='Lost gain is due'
              />
            </Grid>
            {formik.values.lostGainsNeeded && (
              <Grid xs={6}>
                <Grid>
                  <FormControl
                    data-testid='offCycle-dialog-responsibleParty'
                    fullWidth>
                    <InputLabel>Responsible party</InputLabel>
                    <Select
                      autoWidth
                      defaultValue=''
                      label='Responsible party'
                      name='responsibleParty'
                      onChange={formik.handleChange}
                      value={formik.values?.responsibleParty}>
                      <MenuItem value='Employer responsible'>
                        Employer responsible
                      </MenuItem>
                      <MenuItem value='Vestwell responsible'>
                        Vestwell responsible
                      </MenuItem>
                      <MenuItem value='Payroll provider responsible'>
                        Payroll provider responsible
                      </MenuItem>
                      <MenuItem value='Needs further review'>
                        Needs further review
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values?.responsibleParty === 'Needs further review' && (
                  <Grid mt={2}>
                    <TextField
                      data-testid='offCycle-dialog-needsFurtherReview'
                      fullWidth
                      label='Comment'
                      name='comment'
                      onChange={onCommentChanged}
                      value={formik.values?.comment}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {formik.errors?.isExistingDate && (
              <Grid>
                <Alert severity='error'>
                  There’s already an existing off cycle contribution for this
                  pay group on this pay date. The available dates are:{' '}
                  <ul>
                    {formik.values?.availableDates?.map(date => (
                      <li key={date}>
                        <Link
                          component='button'
                          onClick={() => onDateSelect(date)}>
                          {date}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button data-testid='offCycle-dialog-close' onClick={props.onClose}>
            Cancel
          </Button>
          <LoadingButton
            data-testid='offCycle-dialog-submit'
            disabled={!formik.isValid}
            loading={
              payrollSetups.isFetching ||
              contributions.isFetching ||
              formik.isSubmitting
            }
            type='submit'
            variant='contained'>
            Continue
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
