import { useSnackbar } from '@/contexts/SnackBarContext';
import ContributionService from '@/services/Contribution.service';
import { LockOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery } from '@tanstack/react-query';

import { startCase } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';

import { getInvalidHeaders } from '../helpers';
import { HeaderValidationRow } from './HeaderValidationRow';

const isTest = process.env.NODE_ENV === 'test';

type HeaderMappingProps = {
  isOpen: boolean;
  sponsorId: number;
  sponsorPlanId: number;
  ucid: string;
  onClose: () => void;
};

export const HeaderMapping: FC<HeaderMappingProps> = props => {
  const [isMatchedHeadersShows, setIsMatchedHeadersShows] = useToggle(false);
  const [selectedHeaders, setSelectedHeaders] = useState({});
  const snackbar = useSnackbar();

  const headerValidations = useQuery<Record<any, any>>(
    [
      ContributionService.getHeaderValidations.name,
      props.ucid,
      props.sponsorId,
      props.sponsorPlanId
    ],
    () =>
      ContributionService.getHeaderValidations(
        {
          ucid: props.ucid
        },
        {
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    { enabled: isTest }
  );

  const postContributionHeaders = useMutation(
    (headers: any) =>
      ContributionService.postContributionHeaders(
        { ucid: props.ucid },
        {
          headers,
          sponsorId: props.sponsorId,
          sponsorPlanId: props.sponsorPlanId
        }
      ),
    {
      onError: () =>
        snackbar.showSnackbar({
          message: 'Failed to submit contribution headers!',
          severity: 'error'
        })
    }
  );

  const invalidHeaders = useMemo(
    () => getInvalidHeaders(headerValidations.data ?? {}) ?? [],
    [headerValidations.data]
  );

  const duplicatedHeaders = useMemo(
    () => invalidHeaders?.filter(header => header.isDuplicated) ?? [],
    [invalidHeaders]
  );

  const matchedHeaders = useMemo(
    () =>
      headerValidations.data?.validationResults?.filter(
        validation =>
          validation.fileNames.length > 0 && !validation.isDuplicated
      ) ?? [],
    [headerValidations.data?.validationResults]
  );

  const isDisabled = useMemo(
    () =>
      !!Object.keys(selectedHeaders ?? {})?.find(key => !selectedHeaders[key]),
    [selectedHeaders]
  );

  const handleChange = useCallback(({ target }) => {
    setSelectedHeaders(prevState => {
      const matchKey = Object.keys(prevState).find(
        key => prevState[key] === target.value
      );

      return {
        ...prevState,
        [target.name]: target.value,
        ...(matchKey && matchKey !== target.name && { [matchKey]: null })
      };
    });
  }, []);

  const onSubmit = useCallback(async () => {
    const mapping = Object.keys(selectedHeaders).reduce((acc, current) => {
      acc[selectedHeaders[current]] = current;
      return acc;
    }, {});

    await postContributionHeaders.mutateAsync({ mapping });

    await headerValidations.refetch();
  }, [selectedHeaders]);

  const onExpand = useCallback(
    () => setIsMatchedHeadersShows(),
    [setIsMatchedHeadersShows]
  );

  useEffect(() => {
    setSelectedHeaders(
      getInvalidHeaders(headerValidations.data ?? {})?.reduce(
        (acc, current) => {
          acc[current.name] = null;
          return acc;
        },
        {}
      )
    );
  }, [headerValidations.data]);

  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      {duplicatedHeaders?.length ? (
        <>
          <DialogTitle data-testid='headerMapping-dialog-duplicatedHeaders-title'>
            Data Field Warning
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid mb={2}>
                {duplicatedHeaders.map(header => (
                  <Grid
                    data-testid={`headerMapping-dialog-duplicatedHeaders-${header.name}`}
                    key={header.name}
                    my={2}>
                    <Typography
                      data-testid={`headerMapping-dialog-duplicatedHeaders-description-${header.name}`}>
                      You have multiple data fields that we interpret as{' '}
                      <b>{startCase(header.name)}</b>:
                    </Typography>
                    {header.fileNames?.map(name => (
                      <Grid display='flex' key={name} my={1}>
                        <LockOutlined color='error' fontSize='small' />
                        <Typography
                          data-testid={`headerMapping-dialog-duplicatedHeaders-name-${header.name}`}
                          ml={2}>
                          {name}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
              <Grid>
                <Typography>
                  Please make sure that your file does not contain duplicate
                  headers and re-upload.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>Matching Headers From Your File</DialogTitle>
          <DialogContent>
            <Grid container display='flex'>
              <Grid mb={4} xs={12}>
                <Typography>
                  Please select a column from your file to match required
                  fields:
                </Typography>
              </Grid>
              <Grid mb={2} xs={12}>
                <Grid container spacing={2}>
                  <Grid xs>
                    <Typography align='center'>REQUIRED FIELDS</Typography>
                  </Grid>
                  <Grid width={100} xs={2} />
                  <Grid xs>
                    <Typography align='center'>YOUR FILE</Typography>
                  </Grid>
                </Grid>
                <Grid>
                  {invalidHeaders.map(validation => (
                    <HeaderValidationRow
                      handleChange={handleChange}
                      key={validation.name}
                      selectedHeaders={selectedHeaders}
                      unrecognizedHeaders={
                        headerValidations.data?.unrecognizedHeaders
                      }
                      validation={validation}
                    />
                  ))}
                  {isMatchedHeadersShows &&
                    matchedHeaders.map(validation => (
                      <HeaderValidationRow
                        key={validation.name}
                        validation={validation}
                      />
                    ))}
                </Grid>
              </Grid>
              <Grid display='flex' justifyContent='center' xs={12}>
                <Button onClick={onExpand}>
                  {isMatchedHeadersShows ? 'Hide' : 'Show'}{' '}
                  {matchedHeaders.length} Matching Columns
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button
          className='mr-4'
          data-testid='headerMapping-dialog-cancel'
          onClick={props.onClose}>
          Cancel
        </Button>
        {!duplicatedHeaders?.length && (
          <LoadingButton
            data-testid='headerMapping-dialog-submit'
            disabled={isDisabled}
            loading={
              headerValidations.isFetching || postContributionHeaders.isLoading
            }
            onClick={onSubmit}
            variant='contained'>
            Continue
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
